<template>
  <div class="">
    <!-- <sign-up-modal v-if="hasNewSignupFlow"/> -->
    <div class="">
      <MasterHeader />
      <div class="container container-wide">
        <div class="text-center">
          <h4 class="mb-2">
            <template v-if="isZealandFlow">
              Din QR kode
            </template>
            <template v-else>
              Your QR code
            </template>
          </h4>
          <p>
            <template v-if="isZealandFlow">
              Dette er din personlige Excelerate QR kode.
            </template>
            <template v-else>
              This is your personal Excelerate QR code.
            </template>
            <br /><br />
            <template v-if="isZealandFlow">
              Brug den til events hvor Excelerate er til stede.
            </template>
            <template v-else>
              Use it at events where Excelerate is present to unlock benefits.
            </template>
          </p>
          <div class="pj-loading" v-if="loading"><i class="fas fa-circle-notch fa-spin"></i></div>
          <img v-else class="qr_image" :src="qrCode" alt="" srcset="" />
          <p>
            <template v-if="isZealandFlow">
              Se hvilke events vi er tilstede ved.
            </template>
            <template v-else>
              Check out what events we are attending
            </template>

          </p>
          <router-link to="/events" class="btn button-pink">Events</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MasterHeader from '../../components/MasterHeader.vue';
import SignUpModal from '../../components/signUpModal.vue';
import { base64ToString } from '../../utils/utils';

let userType = base64ToString(localStorage.getItem('i_am'))
let userId = base64ToString(localStorage.getItem('uid'))
let userVerified = localStorage.getItem('u-verified')

export default {
  components: {
    MasterHeader,
    //SignUpModal,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    // if (userVerified == 0 && userType == 'student') {
    //   this.$router.push('/user/student-welcome')
    // }
    // if (userVerified == 0 && userType == 'professional') {
    //   this.$router.push('/user/welcome')
    // }

    if (!this.$store.getters.studentData) {
      this.$store.dispatch('getStudentData', userId).then(() => this.loading = false)
    } else {
      this.loading = false
    }
  },
  computed: {
    user() {
      return this.$store.getters.studentData
    },
    qrCode() {
      return this.user.qrcode

    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
  },
};
</script>

<style>
.qr_image {
  max-width: 300px;
  max-height: 300px;
  margin: 5% 0 10% 0;
}

.pj-loading {
  padding: 50px
}
</style>
